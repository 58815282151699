import _ from 'lodash';
import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import GunbotStorage, { INITIAL_GUNBOT_SETUP_MODE } from 'assets/js/gunbot.storage';

const BitrageDashboard = lazy(() => import('views/Bitrage/Dashboard'));
const CodeEditor = lazy(() => import('views/CodeEditor'));
const TradingviewAddon = lazy(() => import('views/TradingviewAddon'));
const AutoConfig = lazy(() => import('views/AutoConfig'));
const BitrageSettings = lazy(() => import('views/Bitrage/Settings/index'));
const Setup = lazy(() => import('views/StartupGunbotSetup'));
const TradingSettings = lazy(() => import('views/TradingSettings'));
const UTAConfigurator = lazy(() => import('views/UTAConfigurator'));
const ServerConsole = lazy(() => import('views/ServerConsole'));
const Dashboard = lazy(() => import('views/Dashboard'));
const Notes = lazy(() => import('components/Gunbot/Notes'));

class RouteComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate(nextProps) {
    // Use deep comparison to check if any of the specified properties have changed
    if (
      this.props.bitRageActive !== nextProps.bitRageActive ||
      this.props.licenseType !== nextProps.licenseType ||
      this.props.isNotDemo !== nextProps.isNotDemo ||
      this.props.isExchangesEmpty !== nextProps.isExchangesEmpty ||
      this.props.gunthy_wallet !== nextProps.gunthy_wallet ||
      this.props.autoconfigEnabled !== nextProps.autoconfigEnabled 
    ) {
      return true; // Re-render when any of these properties change
    }
    return false; // Avoid re-rendering for other prop changes
  }

  render() {
    const { bitRageActive, licenseType, isNotDemo, isExchangesEmpty, gunthy_wallet } = this.props;
    const shouldShowAlertAddon = licenseType.toLowerCase().includes('ultimate') || licenseType.toLowerCase().includes('tv') || licenseType.toLowerCase().includes('mm') || licenseType.toLowerCase().includes('br') 

    const shouldRedirect = () => {
      const initialGunbotSetupMode = GunbotStorage.get(INITIAL_GUNBOT_SETUP_MODE, "");
      return initialGunbotSetupMode === "#advanced"; // return true if the condition for redirect is met
    };
    if (licenseType === 'one' && isNotDemo) {
      if (isExchangesEmpty) {
        return (
          <Suspense fallback={<div style={{ backgroundColor: '#222', minWidth: '100%', minHeight: '100%' }}></div>}>
            <Switch>
              <Route path="/setup" exact component={props => <Setup {...props} />} />
              <Route path="*">
                <Redirect to={'/setup'} />
              </Route>
            </Switch>
          </Suspense>
        );
      } else {
        return (
          <Suspense fallback={<div style={{ backgroundColor: '#222', minWidth: '100%', minHeight: '100%' }}></div>}>
            <Switch>
              <Route path="/" exact component={props => <Dashboard {...props} />} />
              <Route path="/setup" component={props => <Setup {...props} />} />
              <Route path="/trading-settings" component={props => <TradingSettings {...props} />} />
              <Route path="/chart"></Route>
            </Switch>
          </Suspense>
        );
      }
    } else {
      if ((isExchangesEmpty || gunthy_wallet === '') && isNotDemo) {
        return (
          <Suspense fallback={<div style={{ backgroundColor: '#222', minWidth: '100%', minHeight: '100%' }}></div>}>
            <Switch>
              <Route path="/setup" exact component={props => <Setup {...props} />} />
              <Route path="*">
                <Redirect to={'/setup'} />
              </Route>
            </Switch>
          </Suspense>
        );
      } else {
        return (
          <Suspense fallback={<div style={{ backgroundColor: '#222', minWidth: '100%', minHeight: '100%' }}></div>}>
            <Switch>
              <Route path="/" exact component={props => <Dashboard {...props} />} />
              <Route path="/uta-config" component={props => <UTAConfigurator {...props} />} />
              <Route path="/server-console" component={props => <ServerConsole {...props} />} />
              <Route path="/code-editor" component={props => <CodeEditor {...props} />} />
              {shouldShowAlertAddon && <Route path="/tradingview-addon" component={props => <TradingviewAddon {...props} />} />}
              <Route path="/autoconfig" component={props => <AutoConfig {...props} />} />
              <Route path="/setup" component={props => <Setup {...props} />} />
              <Route path="/trading-settings" component={props => (
                shouldRedirect()
                  ? <Redirect to="/setup#step4" />
                  : <TradingSettings {...props} />
              )} />
              <Route path="/api-slots" component={props => <Setup {...props} />} />
              <Route
                path="/notes"
                component={() => (
                  <Notes currentPair={'all'} currentExchange={'all'} useOverlay={true} />
                )}
              />
              <Route path="/chart"></Route>
              {bitRageActive && (
                <>
                  <Route path="/bitrage" exact component={props => <BitrageDashboard history={props.history} />} />
                  <Route path="/bitrage-settings" component={BitrageSettings} />
                </>
              )}
              <Route path="*">
                <Redirect to={bitRageActive ? '/bitrage' : '/'} />
              </Route>
            </Switch>
          </Suspense>
        );
      }
    }
  }
}

export default RouteComponent;
