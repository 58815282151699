import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Fab, makeStyles, withStyles } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import _ from 'lodash';

import Loading from 'views/loading';
import TVChartContainer from 'components/TVChartContainer';
import NavBar from 'components/Gunbot/NavBar';
import MainAlert from 'components/MainAlert';
import RouteComponentWrapper from './RoutesWrapper';

import { getConnectedExchanges, getIMAP, getWEBHOOKS, loadAvailableGUIData, saveConfig } from 'redux/actions';
import createStream from 'components/TVChartContainer/stream';
import { useSocket } from 'SocketIO';
import useMobileView from 'hooks/useMobileView';
import useEmbededView from 'hooks/useEmbededView';
import { checkGunthyWallet } from 'helpers/gunthy_wallet.helper';

import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'App.css';
import { ScreenshotProvider, useScreenshot } from 'components/Gunbot/ScreenshotProvider';

const MainLayout = ({ history, location }) => {
  const socket = useSocket();
  const dispatch = useDispatch();
  const mobileOpen = useMobileView();
  const embeddedView = useEmbededView(history);
  const config = useSelector(state => state.settings.config, _.isEqual);

  const [loading, setLoading] = useState(true);

  const stream = useMemo(() => createStream(socket), [socket]);

  const loadData = useCallback(() => {
    setLoading(true);
    dispatch(
      loadAvailableGUIData({
        processLoadedConfig: true,
        processLoadedCorememData: true,
        processLoadedConnectedExchanges: true,
        processLoadedIMAP: true,
        processLoadedWEBHOOKS: true,
        processLicenseData: true,
      }),
    )
      .then(({ processLoadedConfig: config = {} }) => {
        document.title = (config?.bot?.INSTANCE_NAME || '') + ' GUNTHY - v29.2.0';
        const gunthy_wallet = config?.bot?.gunthy_wallet;
        return checkGunthyWallet(gunthy_wallet);
      })
      .finally(() => setLoading(false));
  }, [dispatch]);

  const handleChangedGUI = useCallback(
    (changes = {}) => {
      let availableKeys = {};
      if (changes['corememDataChanged']) availableKeys.processLoadedCorememData = true;
      if (changes['CONFIG-CHANGED']) {
        availableKeys.processLoadedConfig = true;
        availableKeys.processLoadedCorememData = true;
        availableKeys.processLoadedConnectedExchanges = true;
        availableKeys.processLoadedIMAP = true;
        availableKeys.processLoadedWEBHOOKS = true;
        availableKeys.processLicenseData = true;
      }

      dispatch(loadAvailableGUIData(availableKeys)).then(() => stream.updateCb());
    },
    [dispatch, stream],
  );

  const handleSaveConfig = useCallback(config => {
    dispatch(saveConfig(config));
    dispatch(getIMAP());
    dispatch(getWEBHOOKS());
    dispatch(getConnectedExchanges());
  }, [dispatch]);

  useEffect(() => {
    loadData();
    socket.on('connect', loadData);
    socket.on('Changed-GUI', handleChangedGUI);

    return () => {
      socket.off('connect', loadData);
      socket.off('Changed-GUI', handleChangedGUI);
    };
  }, [loadData, handleChangedGUI, socket]);

  if (loading) {
    return <Loading />;
  }

  return (
    <ScreenshotProvider>
      <MainLayoutContent 
        history={history} 
        location={location} 
        config={config} 
        embeddedView={embeddedView} 
        mobileOpen={mobileOpen} 
        handleSaveConfig={handleSaveConfig}
        stream={stream}
      />
    </ScreenshotProvider>
  );
};

const MainLayoutContent = ({ history, location, config, embeddedView, mobileOpen, handleSaveConfig, stream }) => {
  const { componentRef } = useScreenshot();

  return (
    <div ref={componentRef}>
      <div className="App">
        {!embeddedView && <FloatingButtonWrapper isMobile={mobileOpen} onClick={() => handleSaveConfig(config)} />}
        {!embeddedView && <NavBar history={history} />}
        {!embeddedView && <MainAlert />}

        <div>
          <RouteComponentWrapper config={config} />
          {location.pathname !== '/chart' && <InnerLoadingView />}
          <TVChartContainer history={history} stream={stream} />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;

const FloatingButton = withStyles(() => ({
  root: {
    position: 'fixed',
    bottom: 15,
    right: 15,
    'z-index': 10000,
  },
}))(Fab);

const FloatingButtonWrapper = ({ onClick, isMobile }) => {
  const isChanged = useSelector(state => state.settings.IS_CHANGED);

  if (!isChanged || !isMobile) return null;

  return (
    <FloatingButton color="primary" aria-label="add" onClick={onClick}>
      <Save />
    </FloatingButton>
  );
};

const useStyle = makeStyles(theme => ({
  root: {
    bottom: 0,
    right: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    overflowX: 'hidden',
    background: theme.palette.background.default,
    textAlign: 'left',
  },
}));

const InnerLoadingView = () => {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <div className={'gunbot-dialog-box'}>
        <div
          style={{
            minWidth: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      </div>
    </div>
  );
};
